

.day-canvas {
  position:absolute;
  top:0;
  left:0;
}

#bgCanvas {
  overflow: hidden;
  position: absolute;
  left: 0;
}

#background {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

/*Downloaded from https://www.codeseek.co/matchboxhero/animated-svg-birds-RLebOY */
@import url("https://fonts.googleapis.com/css?family=Arima+Madurai:300");
*,
*::before,
*::after {
  box-sizing: border-box;
}

h1 {
  font-family: 'Arima Madurai', cursive;
  color: black;
  font-size: 4rem;
  letter-spacing: -3px;
  text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.6);
  position: relative;
  z-index: 3;
}

.container {
  z-index: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35rem;
  background-image: linear-gradient(to bottom, rgba(255, 168, 76, 0.6) 0%, rgba(255, 123, 13, 0.6) 100%), url("https://images.unsplash.com/photo-1446824505046-e43605ffb17f");
  background-blend-mode: soft-light;
  background-size: cover;
  background-position: center center;
  padding: 2rem;
}

.bird {
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/174479/bird-cells.svg);
  background-size: auto 100%;
  width: 88px;
  height: 125px;
  will-change: background-position;
  -webkit-animation-name: fly-cycle;
          animation-name: fly-cycle;
  -webkit-animation-timing-function: steps(10);
          animation-timing-function: steps(10);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.bird--one {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.bird--two {
  -webkit-animation-duration: 0.9s;
          animation-duration: 0.9s;
  -webkit-animation-delay: -0.75s;
          animation-delay: -0.75s;
}
.bird--three {
  -webkit-animation-duration: 1.25s;
          animation-duration: 1.25s;
  -webkit-animation-delay: -0.25s;
          animation-delay: -0.25s;
}
.bird--four {
  -webkit-animation-duration: 1.1s;
          animation-duration: 1.1s;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

.bird-container {
  position: absolute;
  top: 20%;
  left: -7.5vw;
  -webkit-transform: scale(0);
          transform: scale(0);
  will-change: transform;
  -webkit-animation-name: fly-right-one;
          animation-name: fly-right-one;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.bird-container--one {
  z-index: 1;
  -webkit-animation-duration: 15s;
          animation-duration: 15s;
  -webkit-animation-delay: 0;
          animation-delay: 0;
}
.bird-container--two {
  -webkit-animation-duration: 16s;
          animation-duration: 16s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.bird-container--three {
  -webkit-animation-duration: 14.6s;
          animation-duration: 14.6s;
  -webkit-animation-delay: 9.5s;
          animation-delay: 9.5s;
}
.bird-container--four {
  z-index: 1;
  -webkit-animation-duration: 16s;
          animation-duration: 16s;
  -webkit-animation-delay: 10.25s;
          animation-delay: 10.25s;
}

@-webkit-keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}

@keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}
@-webkit-keyframes fly-right-one {
  0% {
    left: -10%;
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
  10% {
    left: 10%;
    -webkit-transform: translateY(2vh) scale(0.4);
            transform: translateY(2vh) scale(0.4);
  }
  20% {
    left: 30%;
    -webkit-transform: translateY(0vh) scale(0.5);
            transform: translateY(0vh) scale(0.5);
  }
  30% {
    left: 50%;
    -webkit-transform: translateY(4vh) scale(0.6);
            transform: translateY(4vh) scale(0.6);
  }
  40% {
    left: 70%;
    -webkit-transform: translateY(2vh) scale(0.6);
            transform: translateY(2vh) scale(0.6);
  }
  50% {
    left: 90%;
    -webkit-transform: translateY(0vh) scale(0.6);
            transform: translateY(0vh) scale(0.6);
  }
  60% {
    left: 110%;
    -webkit-transform: translateY(0vh) scale(0.6);
            transform: translateY(0vh) scale(0.6);
  }
  100% {
    left: 110%;
    opacity: 1;
    -webkit-transform: translateY(0vh) scale(0.6);
            transform: translateY(0vh) scale(0.6);
  }
}
@keyframes fly-right-one {
  0% {
    left: -10%;
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
  10% {
    left: 10%;
    -webkit-transform: translateY(2vh) scale(0.4);
            transform: translateY(2vh) scale(0.4);
  }
  20% {
    left: 30%;
    -webkit-transform: translateY(0vh) scale(0.5);
            transform: translateY(0vh) scale(0.5);
  }
  30% {
    left: 50%;
    -webkit-transform: translateY(4vh) scale(0.6);
            transform: translateY(4vh) scale(0.6);
  }
  40% {
    left: 70%;
    -webkit-transform: translateY(2vh) scale(0.6);
            transform: translateY(2vh) scale(0.6);
  }
  50% {
    left: 90%;
    -webkit-transform: translateY(0vh) scale(0.6);
            transform: translateY(0vh) scale(0.6);
  }
  60% {
    left: 110%;
    -webkit-transform: translateY(0vh) scale(0.6);
            transform: translateY(0vh) scale(0.6);
  }
  100% {
    left: 110%;
    opacity: 1;
    -webkit-transform: translateY(0vh) scale(0.6);
            transform: translateY(0vh) scale(0.6);
  }
}
@-webkit-keyframes fly-right-two {
  0% {
    left: -10%;
    opacity: 1;
    -webkit-transform: translateY(-2vh) scale(0.5);
            transform: translateY(-2vh) scale(0.5);
  }
  10% {
    left: 10%;
    -webkit-transform: translateY(0vh) scale(0.4);
            transform: translateY(0vh) scale(0.4);
  }
  20% {
    left: 30%;
    -webkit-transform: translateY(-4vh) scale(0.6);
            transform: translateY(-4vh) scale(0.6);
  }
  30% {
    left: 50%;
    -webkit-transform: translateY(1vh) scale(0.45);
            transform: translateY(1vh) scale(0.45);
  }
  40% {
    left: 70%;
    -webkit-transform: translateY(-2.5vh) scale(0.5);
            transform: translateY(-2.5vh) scale(0.5);
  }
  50% {
    left: 90%;
    -webkit-transform: translateY(0vh) scale(0.45);
            transform: translateY(0vh) scale(0.45);
  }
  51% {
    left: 110%;
    -webkit-transform: translateY(0vh) scale(0.45);
            transform: translateY(0vh) scale(0.45);
  }
  100% {
    left: 110%;
    -webkit-transform: translateY(0vh) scale(0.45);
            transform: translateY(0vh) scale(0.45);
  }
}
@keyframes fly-right-two {
  0% {
    left: -10%;
    opacity: 1;
    -webkit-transform: translateY(-2vh) scale(0.5);
            transform: translateY(-2vh) scale(0.5);
  }
  10% {
    left: 10%;
    -webkit-transform: translateY(0vh) scale(0.4);
            transform: translateY(0vh) scale(0.4);
  }
  20% {
    left: 30%;
    -webkit-transform: translateY(-4vh) scale(0.6);
            transform: translateY(-4vh) scale(0.6);
  }
  30% {
    left: 50%;
    -webkit-transform: translateY(1vh) scale(0.45);
            transform: translateY(1vh) scale(0.45);
  }
  40% {
    left: 70%;
    -webkit-transform: translateY(-2.5vh) scale(0.5);
            transform: translateY(-2.5vh) scale(0.5);
  }
  50% {
    left: 90%;
    -webkit-transform: translateY(0vh) scale(0.45);
            transform: translateY(0vh) scale(0.45);
  }
  51% {
    left: 110%;
    -webkit-transform: translateY(0vh) scale(0.45);
            transform: translateY(0vh) scale(0.45);
  }
  100% {
    left: 110%;
    -webkit-transform: translateY(0vh) scale(0.45);
            transform: translateY(0vh) scale(0.45);
  }
}
