@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

.App {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.info-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: white;
}

.info-name {
  font-size: 8rem;
  font-weight: 500;
}

.main-container {
  height: 100px;
  background-color: rgb(240, 240, 240);
}

.showBack {
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events:none;
  position: fixed;
  width: 100%;
  bottom: 30px;
}

.back-up {
  cursor: pointer;
  background-color: transparent;
  pointer-events:auto;
  width: fit-content;
  border-radius: 50%;
  padding: 0px 10px;
}

.expand-down {
  cursor: pointer;
  border-radius: 50%;
  border-style: solid;
  border-width: 3px;
  border-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 5%;
}

.back-up-dark {
  cursor: pointer;
  background-color: transparent;
  pointer-events:auto;
  width: fit-content;
  border-radius: 50%;
  padding: 0px 10px;
  color: #d3d3d3;
}

.expand-down:hover {
  background-color: rgba(255, 255, 255, 0.137);
  transform: scale(1.1);
}

@media (max-width: 850px) {
  .info-name {
    font-size: 6rem;
  }

  .showBack {
    display: none;
  }
}

@media (max-width: 600px) {
  .info-name {
    font-size: 3rem;
  }

  .showBack {
    bottom: -65px;
  }
}


